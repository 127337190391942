import { DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterOutlet } from '@angular/router';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { TranslocoModule } from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular';
import { AuthApi } from '@web/shared/data-access/model';
import { CLIENT_INJECTION_TOKEN, ErrorInterceptor, LoadingInterceptor } from '@web/web/core/auth/data-access';
import { WebCoreI18nModule } from '@web/web/core/i18n';
import { PromoterAuthInterceptor, PromoterRefreshTokenInterceptor } from '@web/web/promoter/core/auth/data-access';
import { WebPromoterShellModule } from '@web/web/promoter/shell';
import { BASE_URL_TOKEN, HERE_MAPS_API_KEY_TOKEN, HERE_MAPS_APP_ID_TOKEN } from '@web/web/shared/data-access/api';
import { AlertContainerComponent } from '@web/web/shared/ui/alert-container';
import { FeatherIconModule } from '@web/web/shared/ui/feather-icon';
import { ProgressBarComponent } from '@web/web/shared/ui/progress-bar';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { AppComponent } from './app.component';

// noinspection TypeScriptUnresolvedReference
@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    RouterOutlet,
    WebPromoterShellModule,
    ProgressBarComponent,
    AlertContainerComponent,
    FeatherIconModule,
    BrowserAnimationsModule,
    TranslocoModule,
    WebCoreI18nModule,
    NgxGoogleAnalyticsModule.forRoot('G-KBY9L2VPK6'),
    NgxGoogleAnalyticsRouterModule,
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictActionSerializability: true,
          strictActionImmutability: true,
          strictStateImmutability: true,
          strictActionTypeUniqueness: true,
        },
      },
    ),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    EffectsModule.forRoot(),
  ],
  providers: [
    { provide: CLIENT_INJECTION_TOKEN, useValue: AuthApi.Client.PROMO_APP },
    DecimalPipe,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    {
      provide: BASE_URL_TOKEN,
      useValue: `${process.env['BASE_URL']}`,
    },
    {
      provide: HERE_MAPS_APP_ID_TOKEN,
      useValue: `${process.env['HERE_MAPS_APP_ID']}`,
    },
    {
      provide: HERE_MAPS_API_KEY_TOKEN,
      useValue: `${process.env['HERE_MAPS_API_KEY']}`,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PromoterAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PromoterRefreshTokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
