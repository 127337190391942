import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HereMapsApi, PromoterApi } from '@web/shared/data-access/model';
import { AlertService } from '@web/web/shared/data-access/alert';
import { PromoterApiService } from '@web/web/shared/data-access/api';
import { LanguageSwitchService } from '@web/web/shared/data-access/language';
import { confirmPasswordValidatorFunction } from '@web/web/shared/util/function';
import { take, tap } from 'rxjs';
import { UtmLocalStorageService } from 'web/shared/data-access/utm';
import { strongPasswordValidator } from 'web/shared/util/validator';
import { PromoterAuthViewModel } from './promoter-auth.viewmodel';
import { PromoterLocalStorageService } from '../storage/promoter-local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class RegisterViewModel {
  public formGroup: FormGroup;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly promoterApiService: PromoterApiService,
    private readonly promoterAuthViewModel: PromoterAuthViewModel,
    private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly promoterLocalStorageService: PromoterLocalStorageService,
    private readonly languageSwitchService: LanguageSwitchService,
    private readonly utmLocalStorageService: UtmLocalStorageService,
  ) {}

  public buildForm(): void {
    this.formGroup = this.formBuilder.group(
      {
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        address: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required, strongPasswordValidator]),
        passwordRepeat: new FormControl('', [Validators.required, strongPasswordValidator]),
        termsAgreement: new FormControl('', [Validators.required]),
        privacyAgreement: new FormControl('', [Validators.required]),
      },
      {
        validator: confirmPasswordValidatorFunction('password', 'passwordRepeat'),
      },
    );
  }

  public submit(phoneNr: string): void {
    const promoter: PromoterApi.RegisterPromoter = this.formGroup.value;

    if (!promoter) {
      return;
    }

    promoter.phone = phoneNr;
    promoter.communicationLanguage = this.languageSwitchService.getSelectedLanguage();

    // get UTM data from localstorage
    promoter.utm = this.utmLocalStorageService.getUtmData();

    this.promoterApiService
      .register(promoter)
      .pipe(
        take(1),
        tap(loginResponse => {
          this.promoterAuthViewModel.storeLoginData(loginResponse);
          this.alertService.success('Account created successfully.');
          const promoData = this.promoterLocalStorageService.getPromotionData();

          // clear utm data
          this.utmLocalStorageService.removeUtmData();

          if (promoData) {
            this.router.navigate([`/promotion/${promoData}`]);

            return;
          }
          this.router.navigate([`/dashboard`]);
        }),
      )
      .subscribe();
  }

  public setAddress(address: HereMapsApi.AddressZip | undefined): void {
    this.formGroup.patchValue({ address: address ? address : '' });
    this.formGroup.markAsDirty();
    this.formGroup.markAsTouched();
  }
}
