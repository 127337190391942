import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PromoterAuthViewModel } from '@web/web/promoter/core/auth/data-access';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AllPromosGuard {
  constructor(private readonly promoterAuthViewModel: PromoterAuthViewModel) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return !!this.promoterAuthViewModel.getLoginData()?.isBookmarked;
  }
}
