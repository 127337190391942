import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PromoterApi } from '@web/shared/data-access/model';
import { AuthGuard } from '@web/web/core/auth/data-access';
import { PromoterAuthViewModel } from '../viewmodel/promoter-auth.viewmodel';
import { PromoterLoginViewModel } from '../viewmodel/promoter-login.viewmodel';

@Injectable({
  providedIn: 'root',
})
export class PromoterAuthGuard extends AuthGuard<PromoterApi.PromoterLoginResponse> {
  constructor(
    employerAuthViewModel: PromoterAuthViewModel,
    promoterLoginViewModel: PromoterLoginViewModel,
    router: Router,
  ) {
    super(employerAuthViewModel, promoterLoginViewModel, router);
  }
}
