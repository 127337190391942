import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WebCoreI18nModule } from '@web/web/core/i18n';
import { NavSideModule } from '@web/web/shared/ui/nav-side';
import { PanelTopModule } from '@web/web/shared/ui/panel-top';
import { TextVariantPipe } from '@web/web/shared/util/pipes';
import { GlobalStoreModule } from 'web/shared/data-access/global';
import { LayoutComponent } from './layout/layout.component';
import { routes } from './routes';

@NgModule({
  imports: [
    CommonModule,
    WebCoreI18nModule,
    RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', bindToComponentInputs: true }),
    PanelTopModule,
    NavSideModule,
    GlobalStoreModule,
  ],
  declarations: [LayoutComponent],
  providers: [TextVariantPipe],
})
export class WebPromoterShellModule {}
