import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { PromotionApi } from '@web/shared/data-access/model';
import { AlertService } from '@web/web/shared/data-access/alert';
import { PromotionApiService } from '@web/web/shared/data-access/api';
import { Observable, switchMap, take, tap } from 'rxjs';

export interface PromotionState {
  myPromotions: PromotionApi.Promotion[];
  allAvailablePromotions: PromotionApi.Promotion[];
  successDialogPromotionId: string | undefined;
  isSuccessDialogOpen: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PromotionsViewModel extends ComponentStore<PromotionState> {
  public readonly vm$: Observable<PromotionState> = this.select(state => ({
    myPromotions: state.myPromotions,
    allAvailablePromotions: state.allAvailablePromotions,
    successDialogPromotionId: state.successDialogPromotionId,
    isSuccessDialogOpen: state.isSuccessDialogOpen,
  }));

  constructor(
    private readonly promotionApiService: PromotionApiService,
    private readonly alertService: AlertService,
  ) {
    super({
      myPromotions: [],
      allAvailablePromotions: [],
      successDialogPromotionId: undefined,
      isSuccessDialogOpen: false,
    });
  }

  public getAllAssignedPromotionsToUser(): void {
    this.promotionApiService
      .getAllAssignedPromotionsToUser()
      .pipe(
        take(1),
        tap(myPromotions => this.patchState({ myPromotions })),
      )
      .subscribe();
  }

  public getAllAvailablePromotions(distance?: number): void {
    this.promotionApiService
      .getAllAvailablePromotions(distance)
      .pipe(
        take(1),
        tap(allAvailablePromotions => this.patchState({ allAvailablePromotions })),
      )
      .subscribe();
  }

  public assignPromotionToUser(promotionId: string): void {
    this.promotionApiService
      .assignPromotionToUser(promotionId)
      .pipe(
        take(1),
        switchMap(() =>
          this.promotionApiService
            .getAllAvailablePromotions()
            .pipe(tap(allAvailablePromotions => this.patchState({ allAvailablePromotions }))),
        ),
        tap(() => {
          this.alertService.success('Promotion assign to you successfully');
          this.initSuccessDialog(promotionId);
        }),
      )
      .subscribe();
  }

  public initSuccessDialog(promotionId: string): void {
    this.patchState({ successDialogPromotionId: promotionId });
  }

  public closeSuccessDialog(): void {
    this.patchState({ successDialogPromotionId: undefined });
  }
}
