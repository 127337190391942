import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { PromoterApi } from '@web/shared/data-access/model';
import { ResetPasswordViewModel } from '@web/web/core/auth/data-access';
import { AlertService } from '@web/web/shared/data-access/alert';
import { PromoterApiService } from '@web/web/shared/data-access/api';
import { TimerService } from '@web/web/shared/data-access/timer';

@Injectable({
  providedIn: 'root',
})
export class PromoterResetPasswordViewModel extends ResetPasswordViewModel<PromoterApi.PromoterLoginResponse> {
  constructor(
    formBuilder: FormBuilder,
    promoterApiService: PromoterApiService,
    alertService: AlertService,
    timerService: TimerService,
    router: Router,
  ) {
    super(formBuilder, promoterApiService, alertService, timerService, router);
  }
}
