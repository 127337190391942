import { Component, OnInit } from '@angular/core';
import { TranslocoApi } from '@web/shared/data-access/model';
import {
  PromoterAuthViewModel,
  PromoterLocalStorageService,
  PromoterLoginViewModel,
} from '@web/web/promoter/core/auth/data-access';
import { PromoSidenavService } from '@web/web/promoter/shared/data-access';
import { PromoterApiService } from '@web/web/shared/data-access/api';
import { LanguageSwitchService } from '@web/web/shared/data-access/language';
import { tap } from 'rxjs';

@Component({
  selector: 'wh-promo-app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  constructor(
    public readonly promoterAuthViewModel: PromoterAuthViewModel,
    public readonly promoterLoginViewModel: PromoterLoginViewModel,
    private readonly promoterLocalStorageService: PromoterLocalStorageService,
    private readonly promoterApiService: PromoterApiService,
    public readonly sidenavService: PromoSidenavService,
    public readonly languageSwitchService: LanguageSwitchService,
  ) {}

  public ngOnInit(): void {
    this.promoterLoginViewModel.redirect();
  }

  public logout(): void {
    this.promoterApiService
      .logout()
      .pipe(
        tap(() => {
          this.promoterAuthViewModel.logout();
        }),
      )
      .subscribe();
  }

  public switchLanguage(selectedLanguage: TranslocoApi.Locale): void {
    if (!selectedLanguage) {
      return;
    }

    this.languageSwitchService.setSelectedLanguage(selectedLanguage);
    this.promoterLocalStorageService.storeSelectedLanguage(selectedLanguage);
  }
}
