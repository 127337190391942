import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginViewModel } from '@web/web/core/auth/data-access';
import { AlertService } from '@web/web/shared/data-access/alert';
import { PromoterApiService } from '@web/web/shared/data-access/api';
import { tap } from 'rxjs';
import { PromoterAuthViewModel } from './promoter-auth.viewmodel';
import { PromoterLocalStorageService } from '../storage/promoter-local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class PromoterLoginViewModel extends LoginViewModel {
  protected defaultRedirectUrl = '/dashboard';

  constructor(
    formBuilder: FormBuilder,
    router: Router,
    private readonly promoterApiService: PromoterApiService,
    private readonly promoterAuthViewModel: PromoterAuthViewModel,
    private readonly alertService: AlertService,
    private readonly promoterLocalStorageService: PromoterLocalStorageService,
  ) {
    super(formBuilder, router);
  }

  public submit(): void {
    const loginData = this.getLoginData();

    if (!loginData) {
      return;
    }

    this.promoterApiService
      .login({ ...loginData })
      .pipe(
        tap(loginResponseDto => {
          this.promoterAuthViewModel.storeLoginData(loginResponseDto);

          const promoData = this.promoterLocalStorageService.getPromotionData();

          if (promoData) {
            this.router.navigate([`/promotion/${promoData}`]);

            return;
          }

          this.redirect();
          this.alertService.success(`You are successfully logged in. Welcome ${loginResponseDto.firstName}`);
        }),
      )
      .subscribe();
  }
}
