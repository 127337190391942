<wh-s-panel-top
  [hasAvatar]="false"
  [languages]="(languageSwitchService.vm$ | async)?.languagesNew ?? []"
  (languageClicked)="switchLanguage($event)"
  (toggleDrawer)="sidenavService.toggleDrawer()"
></wh-s-panel-top>

<wh-s-nav-side
  [sideNavItems]="sidenavService.navSideItems"
  [isDrawerOpen]="sidenavService.isDrawerOpen"
  (toggleDrawer)="sidenavService.toggleDrawer($event)"
  (logout)="logout()"
>
  <main slot="content">
    <router-outlet></router-outlet>
  </main>
</wh-s-nav-side>
