import { Routes } from '@angular/router';
import { PromoterAuthGuard, PromoterLoggedInGuard } from '@web/web/promoter/core/auth/data-access';
import { AllPromosGuard } from '@web/web/promoter/domain/promotion/data-access';
import { NotFoundComponent } from 'web/shared/ui/not-found';
import { LayoutComponent } from './layout/layout.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [PromoterAuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: async () => (await import('@web/web/promoter/domain/dashboard/feature/shell')).DashboardModule,
      },
      {
        path: 'my-promotions',
        loadChildren: async () => (await import('@web/web/promoter/domain/promotion/feature/list')).ListModule,
      },
      {
        path: 'promotions',
        loadChildren: async () => (await import('@web/web/promoter/domain/promotion/feature/list-all')).ListAllModule,
        canActivate: [AllPromosGuard],
      },
      {
        path: 'faq',
        loadChildren: async () => (await import('@web/web/promoter/domain/knowledge-hub/feature/faq')).FaqModule,
      },
      {
        path: 'success-stories',
        loadChildren: async () =>
          (await import('@web/web/promoter/domain/knowledge-hub/feature/success-stories')).SuccessStoriesModule,
      },
    ],
  },
  {
    path: 'promotion/:id',
    loadChildren: async () => (await import('@web/web/shared/feature/job-promotion')).PromotionModule,
    data: {
      promotionId: ':id',
    },
  },
  {
    path: 'auth',
    canActivate: [PromoterLoggedInGuard],
    loadChildren: async () =>
      (await import('@web/web/promoter/core/auth/feature/shell')).WebPromoterCoreAuthFeatureShellModule,
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];
