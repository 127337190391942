import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PromoterApi } from '@web/shared/data-access/model';
import { AuthViewModel } from '@web/web/core/auth/data-access';
import { PromoterApiService } from '@web/web/shared/data-access/api';
import { PromoterLocalStorageService } from '../storage/promoter-local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class PromoterAuthViewModel extends AuthViewModel<PromoterApi.PromoterLoginResponse> {
  public readonly LOGOUT_ROUTE = 'auth/login';

  constructor(
    private readonly promoterLocalStorageService: PromoterLocalStorageService,
    router: Router,
    promoterApiService: PromoterApiService,
  ) {
    super(promoterLocalStorageService, router, promoterApiService);
  }
}
