import { ChangeDetectionStrategy, Component } from '@angular/core';
import Hotjar from '@hotjar/browser';
import { AlertService } from '@web/web/shared/data-access/alert';
import { LoadingViewModel } from '@web/web/shared/data-access/loading';

@Component({
  selector: 'wh-promo-app-root',
  template: `
    <!-- Global progress bar feature -->
    <wh-s-progress-bar *ngIf="loadingViewModel.isLoading$ | async"></wh-s-progress-bar>

    <!-- Global alert feature, use ** alertService ** anywhere in app to show alerts -->
    <wh-s-alert-container
      *ngIf="alertService.vm$ | async as vm"
      [items]="vm.items"
      (closeItem)="alertService.removeAlert($event)"
    ></wh-s-alert-container>

    <router-outlet></router-outlet>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100vh;
        justify-content: center;

        & > wh-s-progress-bar {
          position: sticky;
          top: 0;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private siteId = 3663661;
  private hotjarVersion = 6;

  constructor(
    public readonly loadingViewModel: LoadingViewModel,
    public readonly alertService: AlertService,
  ) {
    Hotjar.init(this.siteId, this.hotjarVersion);
  }
}
