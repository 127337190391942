import { Injectable } from '@angular/core';
import { PromoterAuthViewModel } from '@web/web/promoter/core/auth/data-access';
import { NavSideGroup, NavSideItemActionType } from '@web/web/shared/data-access/type';
import { BehaviorSubject, filter, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PromoSidenavService {
  public loggedInUserName = '';
  public isDrawerOpen = true;
  private isPromoterBookmarked$ = new BehaviorSubject(false);

  public navSideItems: NavSideGroup[] = [];
  constructor(private readonly promoterAuthViewModel: PromoterAuthViewModel) {
    this.promoterAuthViewModel.isAuthenticated$
      .pipe(
        filter(isAuthenticated => isAuthenticated),
        tap(() => {
          const loginData = this.promoterAuthViewModel.getLoginData();
          if (!loginData) return;

          this.isPromoterBookmarked$.next(this.promoterAuthViewModel.getLoginData()?.isBookmarked ?? false);
          this.loggedInUserName = `${loginData.firstName} ${loginData.lastName.charAt(0).toUpperCase()}.`;
        }),
      )
      .subscribe();

    this.isPromoterBookmarked$.subscribe(isPromoterBookmarked => {
      this.navSideItems = [
        {
          title: 'shared.sidenav.home.title',
          items: [
            {
              title: 'shared.sidenav.home.dashboard',
              icon: 'grid',
              url: '/dashboard',
            },
          ],
        },
        {
          title: 'shared.sidenav.promotions.title',
          items: [
            {
              title: 'shared.sidenav.promotions.promotions',
              icon: 'heart',
              url: '/my-promotions',
            },
            {
              title: 'shared.sidenav.promotions.promotions-all',
              icon: 'dollar-sign',
              url: '/promotions',
              hidden: !isPromoterBookmarked,
            },
          ],
        },
        {
          title: 'shared.sidenav.knowledge-hub.title',
          items: [
            {
              title: 'shared.sidenav.knowledge-hub.faq',
              icon: 'help-circle',
              url: '/faq',
            },
            {
              title: 'shared.sidenav.knowledge-hub.success-stories',
              icon: 'award',
              url: '/success-stories',
            },
          ],
        },
        {
          title: 'shared.sidenav.account.title',
          items: [
            {
              title: 'shared.sidenav.account.logout',
              icon: 'log-out',
              actionType: NavSideItemActionType.LOGOUT,
            },
          ],
        },
      ];
    });
  }

  public toggleDrawer(isDrawerOpen?: void | boolean | undefined): void {
    if (typeof isDrawerOpen == 'boolean') {
      this.isDrawerOpen = isDrawerOpen;

      return;
    }

    this.isDrawerOpen = !this.isDrawerOpen;
  }
}
