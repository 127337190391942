import { Injectable } from '@angular/core';
import { PromoterApi, TranslocoApi } from '@web/shared/data-access/model';
import { AuthLocalStorageService } from '@web/web/core/auth/data-access';
import { enumToArray } from '@web/web/shared/util/function';

@Injectable({
  providedIn: 'root',
})
export class PromoterLocalStorageService extends AuthLocalStorageService<PromoterApi.PromoterLoginResponse> {
  private readonly selectedLanguageKey = 'selected_language_key';
  public readonly promotionDataKey = 'promotion_data_key';

  public storeSelectedLanguage(language: TranslocoApi.Locale): void {
    localStorage.setItem(this.selectedLanguageKey, language);
  }

  // Check the locale storage to see if user has already set his preferred language
  // If there is no data, return english as default
  // TODO: Check if default should be german...
  public getSelectedLanguage(): TranslocoApi.Locale {
    const lang = localStorage.getItem(this.selectedLanguageKey);
    if (!lang) return TranslocoApi.Locale.EN;

    const localeArr = enumToArray(TranslocoApi.Locale);

    return localeArr.find(l => l === lang) ?? TranslocoApi.Locale.EN;
  }

  public setPromotionData(promotionId: string): void {
    localStorage.setItem(this.promotionDataKey, promotionId);
  }

  public getPromotionData(): string {
    return localStorage.getItem(this.promotionDataKey) ?? '';
  }

  public removePromotionData(): void {
    localStorage.removeItem(this.promotionDataKey);
  }
}
