import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PromoterApi } from '@web/shared/data-access/model';
import { LoggedInGuard } from '@web/web/core/auth/data-access';
import { PromoterAuthViewModel } from '../viewmodel/promoter-auth.viewmodel';

@Injectable({
  providedIn: 'root',
})
export class PromoterLoggedInGuard extends LoggedInGuard<PromoterApi.PromoterLoginResponse> {
  protected constructor(promoterAuthViewModel: PromoterAuthViewModel, router: Router) {
    super(promoterAuthViewModel, router, 'dashboard');
  }
}
