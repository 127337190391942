import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PromoterApi } from '@web/shared/data-access/model';
import { RefreshTokenInterceptor } from '@web/web/core/auth/data-access';
import { PromoterApiService } from '@web/web/shared/data-access/api';
import { PromoterAuthViewModel } from '../viewmodel/promoter-auth.viewmodel';

@Injectable()
export class PromoterRefreshTokenInterceptor extends RefreshTokenInterceptor<PromoterApi.PromoterLoginResponse> {
  constructor(promoterApiService: PromoterApiService, promoterAuthViewModel: PromoterAuthViewModel, router: Router) {
    super(promoterApiService, promoterAuthViewModel, router);
  }
}
